<template>
  <div class="page-bg">
    <Alert v-if="authError" :title="'Password Reset'" floating alert-type="danger">
      <p>
        A password reset link could not be sent for the following reason: {{authError}}.
        If the issue persists, please reach out to the Ancestral Homes team at info@ancestralhomes.net.
      </p>
    </Alert>
    <Alert v-else-if="resetPasswordInProgress && authStatus" :title="'Password Reset'" floating>
      <p>A link to reset the password for this account has been sent to {{ accountEmail }}.</p>
    </Alert>
    <h2>Account Settings</h2>
    <div>
      <h5>Contact Information</h5>
      <div class="light-teal-bg">
        <div class="input-section">
          <label for="contactEmail" class="form-label">Contact Email</label>
          <InfoIcon :tooltip-text="emailTooltip"/>
          <input
              v-model="userEmail"
              type="email"
              :class="['form-control', {'invalid-field': !isEmailValid}]"
              id="contactEmail"
          >
          <p v-if="!isEmailValid" class="input-error">Invalid email address</p>
          <p v-else-if="userEmail !== accountEmail" class="disclaimer">
            Please note that the original email address you signed up with, {{accountEmail}}, must still be used when
            logging into your account or resetting your password.
          </p>
        </div>
      </div>
    </div>
    <div>
      <NotificationBadge fit-content small :tooltip-text="consentPrompt" :show-badge="showConsentPrompt">
        <template v-bind:slot="'notification-parent'">
          <h5>Community</h5>
        </template>
      </NotificationBadge>
      <div class="light-teal-bg">
        <div class="input-section">
          <label class="form-label">Social Media Sharing</label>
          <InfoIcon :tooltip-text="socialSharingTooltip"/>
          <p>
            Many of our most interesting homes come from users like you, and we would love the opportunity to share some
            of
            them with our followers on our official Ancestral Homes Network social media accounts
            (Facebook, Instagram, LinkedIn, and YouTube).
            <br>
            However, we understand that not everyone is keen to have their work shared in this way, and our main
            commitment
            will always be to ensuring that everything you add to our database remains safe, properly credited, and
            fully
            yours to control.
            <br><br>
            All users are opted out of social media sharing unless explicit permission is given.
          </p>
          <div class="col-12 radio-buttons">
            <div>
              <input v-model="sharingConsent" value="true" type="radio" class="form-check-input" id="sharingConsent">
              <label class="form-check-label" for="sharingConsent">I consent to AHN sharing my homes on social
                media.</label>
            </div>
            <div>
              <input v-model="sharingConsent" value="false" type="radio" class="form-check-input"
                     id="notSharingConsent">
              <label class="form-check-label" for="notSharingConsent">I do NOT consent to AHN sharing my homes on social
                media.</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button :class="['btn', 'btn-primary', 'save-btn', {'btn-disabled': disableSave}]" @click="saveSettings">
      Save Changes
    </button>
    <div>
      <h5>Manage Account</h5>
        <button
          class="btn btn-primary btn-lg reset-pass-btn"
          @click="resetPasswordClicked">
          Reset Password
        </button>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Alert';
import InfoIcon from '@/components/InfoIcon';
import {mapActions, mapGetters} from "vuex";
import {validate} from "@/helpers/utils";
import NotificationBadge from "@/components/NotificationBadge.vue";
import {TOOLTIPS} from "@/helpers/constants";

export default {
  name: "ProfileSettings",
  components: {NotificationBadge, InfoIcon, Alert},
  data() {
    return {
      resetPasswordInProgress: false,
      userEmail: null,
      emailTooltip: 'This is the email address that the Ancestral Homes Network will use to contact you if you submit a ' +
          'question, or if we have important information to share with you regarding our terms and conditions and ' +
          'privacy policy. As outlined in the privacy policy, we will never share this email address with third parties ' +
          'for marketing purposes.',
      sharingConsent: false,
      socialSharingTooltip: 'By selecting the "I consent" button below, you allow the Ancestral Homes Network to share ' +
          'any unhidden homes that you have published to the Ancestral Homes Network database on their social media ' +
          'accounts. You may revoke your consent at any time.',
      consentPrompt: TOOLTIPS.consentPromptShort,
    }
  },
  mounted() {
    this.userEmail = this.contactEmail;
    this.sharingConsent = this.userSettings.consents_to_sharing || false;
  },
  watch: {
    authError(to) {
      if (to) {
        setTimeout(() => {
          this.showAuthError = false;
          this.resetAuthError();
        }, 10000);
      }
    },
    authStatus(to){
      if (to) {
        this.resetPasswordInProgress = false
        setTimeout(() => {
          this.resetAuthStatus();
        }, 8000);
      }
    },
    userSettings: {
      deep: true,
      handler() {
        this.userEmail = this.contactEmail;
        this.sharingConsent = this.userSettings.consents_to_sharing || false;
      },
    }
  },
  computed: {
    ...mapGetters(['getLoggedInUser', 'authError', 'authStatus']),
    userID() {
      return this.getLoggedInUser.id;
    },
    userSettings(){
      return this.getLoggedInUser.settings;
    },
    showConsentPrompt(){
      const noPromptNeeded = this.userSettings?.closed_tutorial || this.userSettings?.consents_to_sharing
      return !noPromptNeeded;
    },
    contactEmail(){
      const settingsEmail = this.userSettings ? this.userSettings.contact_email : null;
      return  settingsEmail || this.getLoggedInUser.profile.email;
    },
    accountEmail(){
      // need to keep track of this one, since this will always be the username of the account.
      return this.getLoggedInUser.profile.email;
    },
    hasChanges(){
      const canShare = this.userSettings ? this.userSettings.consents_to_sharing : false;
      const emailIsSame = this.userEmail === this.contactEmail;
      const preferencesAreSame = this.sharingConsent === canShare;
      return !(emailIsSame && preferencesAreSame);
    },
    isEmailValid() {
      return validate.email(this.userEmail);
    },
    disableSave(){
      return !(this.hasChanges && this.isEmailValid);
    },
  },
  methods: {
    ...mapActions(['sendPasswordResetEmail', 'updateUserSettings', 'resetAuthStatus', 'resetAuthError']),
    saveSettings(){
      if(!this.disableSave) {
        this.updateUserSettings({
          user_id: this.userID,
          contact_email: this.userEmail,
          consents_to_sharing: this.sharingConsent,
          closed_tutorial: true, // if user has updated their settings at least once, don't show this prompt again
        });
      }
    },
    resetPasswordClicked() {
      // only send new email if button hasn't been pressed recently
      if (!this.resetPasswordInProgress) {
        // This has to be the email address they used to make the account.
        this.resetPasswordInProgress = true;
        this.sendPasswordResetEmail(this.getLoggedInUser.profile.email);
      }
    },
  }
}
</script>

<style scoped>
.page-bg {
  height: 95%;
  display: flex;
  flex-direction: column;
}

.light-teal-bg {
  padding: 1rem;
}

.manage-acct-buttons .btn {
  margin-right: 1rem;
}

.input-section p {
  font-size: small;
}

.radio-buttons {
  margin-left: 0.5rem;
}

h5 {
  margin-top: 1rem;
}

.save-btn {
  margin: 1rem 0;
}

.disclaimer {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.reset-pass-btn {
  margin-bottom: 1rem;
}

.invalid-field {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px #ce8483;
}

.input-error {
  color: #d9534f;
  font-size: x-small;
  text-align: right;
}

</style>