
export const TOOLTIPS = {
    subdivision: 'The largest geographical, territorial, or political division of a country.',
    city: 'An organized centre of population identified by name on maps.',
    county: 'A territorial division of a country, usually providing certain local government services.',
    municipality: 'A territorial division of a country approximately equivalent to a county.',
    township: 'A territorial division of a country approximately equivalent to a county.',
    parish: 'A territorial entity in many Christian denominations, constituting a sub-division of a diocese.',
    townland: 'The smallest geographical division of a county or parish.',
    concession: 'A surveyed tract of land, typically constituting a sub-division of a county or township.',
    lot: 'A surveyed plot or area of land, the legal ownership of which is registered in the country’s formal land registry system. It is typically a sub-division of a townland or a concession.',
    mapSheetNum: 'A reference number to link together a series of topographic map sheets.',
    mapSeries: 'A collection or group of topographic maps that covers a specific area of a country.',
    address: 'An official description of the approximate location of a home for purposes of delivery of goods and services, especially postal services.',
    structName: 'The name by which the structure is or was referred to by members of the community, or by those who lived in it.',
    consentPrompt: `Thank you for adding to our International Archive of Ancestral Homes! <br/>
         We'd love to share your work with a wider audience, but will never do so without your consent. <br/><br/>
         Consider updating your sharing preferences by clicking the 'Account Settings' button.`,
    consentPromptShort: 'Please consider updating your sharing preferences, so we can share the home records you create ' +
        'with a wider audience.',
};

export const ROUTENAMES = {
  ADD_HOME: 'add',
}