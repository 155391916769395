<template>
  <div class="profile-container">
    <div v-if="getLoggedInUser" class="row">
      <div class="user-info-container teal-bg col-lg-3 col-md-12">
        <div class="top-section">
          <h4>Logged in as</h4>
          <p>{{ userEmail }}</p>
        </div>

        <div class="user-info-buttons">
          <button
              class="btn btn-outline-light btn-lg"
              @click="navRedirect('yourHomes')">
            Your Homes
          </button>
          <button
              class="btn btn-outline-light btn-lg"
              @click="navRedirect('bulkUpload')">
            Bulk Upload
          </button>
          <button
              v-if="userIsAdmin"
              class="btn btn-outline-light btn-lg"
              @click="navRedirect('addBlogPost')">
            Add Blog Post
          </button>
          <NotificationBadge
              :show-badge="showConsentPrompt"
              :tooltip-text="consentPrompt"
          >
            <template v-bind:slot="'notification-parent'">
              <button
                  class="btn btn-outline-light btn-lg"
                  @click="navRedirect('settings')">
                Account Settings
              </button>
            </template>
          </NotificationBadge>
          <button
              v-if="false"
              class="btn btn-outline-light btn-lg"
              @click="navRedirect('manage')">
            Account Management
          </button>
          <button class="btn btn-light btn-lg last-button" @click="logoutClicked">Log Out</button>
        </div>
      </div>

      <div class="homes-container col-lg-9 col-md-12">
        <router-view @homeSearchComplete="updateHomeCount"></router-view>
      </div>
    </div>
    <div v-else class="row">
      <div class="content col-12">
        <h1>Please log in to access your profile.</h1>
        <button class="btn btn-primary btn-lg login-button">
          <router-link class="account-button" to="/login">Log in</router-link>
        </button>
      </div>
      <img class="bg-img-bottom" src="../assets/landscape_bw.png"/>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import NotificationBadge from "@/components/NotificationBadge.vue";
import {TOOLTIPS} from "@/helpers/constants";

export default {
  name: "Profile",
  components: {NotificationBadge},
  data() {
    return {
      showInfoText: false,
      consentPrompt: TOOLTIPS.consentPrompt,
      homeCount: 0,
    }
  },
  watch: {
    showInfoText(to) {
      if (to) {
        // fade out info text after 4s
        setTimeout(() => {
          this.showInfoText = false;
        }, 8000);
      }
    },
  },
  computed: {
    ...mapGetters(['getLoggedInUser']),
    user() {
      return this.getLoggedInUser;
    },
    userEmail() {
      return this.user.profile.email
    },
    userID() {
      return this.user.id;
    },
    userIsAdmin() {
      const { user } = this;
      return user && user.permissions && user.permissions.is_admin;
    },
    showConsentPrompt() {
      const isSettingsPage = this.$route.name === 'settings';
      const { closed_tutorial, consents_to_sharing } = this.user.settings;
      return !isSettingsPage && !consents_to_sharing && !closed_tutorial && this.homeCount > 0;
    }
  },
  methods: {
    ...mapActions(['logoutUser']),
    logoutClicked() {
      this.logoutUser();
      // TODO: make sure this worked instead of immediately redirecting
      this.$router.push({name: 'home'});
    },
    navRedirect(name) {
      this.$router.push({name});
    },
    updateHomeCount(homeCount){
      this.homeCount = homeCount;
    }
  }
}
</script>

<style scoped>
.profile-container .row {
  min-height: 85vh;
  position: relative;
  padding: 0;
}

.user-info-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.user-info-buttons {
  height: fit-content;
  margin-top: auto;
}

.user-info-container button {
  width: 100%;
  margin-bottom: 1rem;
}

.homes-container {
  padding-top: 0.5rem;
  min-height: 88vh;
}

.content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.buttons .btn {
  margin: 1rem;
}

.login-button {
  min-width: 10rem;
}

.account-button {
  color: white;
}

.account-button:hover {
  text-decoration: none;
}

.btn {
  min-width: 11rem;
}

.last-button {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 600px) {
  .profile-container .row {
    min-height: auto;
  }
}
</style>
