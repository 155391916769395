<template>
  <div class="add-blog-container">
    <div v-if="!userIsAdmin" class="no-access text-center">
      <h1>Access Denied</h1>
      <p>Think this is an error? Please reach out to the Ancestral Homes Network team directly.</p>
    </div>
    <div v-else>
      <Alert v-if="resultMessage" :title="resultMessage" :alert-type="alertType" floating/>
      <h1>Add Blog Post</h1>
      <p>Posts made here will appear in the blog section of the site. Once posted, admin users can also delete blog posts from the main blog page.</p>
      <div class="markdown-container bg-light">
        <p>You can use
          <a target="_blank" href="https://commonmark.org/help/">
          Markdown
          </a>
          to add formatting to your entries. Some useful examples include:
        </p>
        <table class="table table-container">
          <thead>
            <tr>
              <th>Formatting option</th>
              <th>What you write</th>
              <th>What it looks like</th>
            </tr>
          </thead>
          <tr v-for="(example, index) in markdownExamples" :key="index">
            <td>{{example.type}}</td>
            <td>{{example.syntax}}</td>
            <td><MarkdownRenderer :source="example.syntax"/></td>
          </tr>
        </table>
      </div>
      <div class="blog-form row">
        <div class="col-12">
          <label for="title" class="form-label">Title</label>
          <input v-model="title" type="text" class="form-control" id="title">
        </div>
        <div class="col-12">
          <label for="author" class="form-label">Author (optional)</label>
          <input v-model="author" type="text" class="form-control" id="author">
        </div>
        <div class="col-12">
          <label for="text" class="form-label">Content</label>
          <textarea v-model="text" class="form-control content-input" id="text"></textarea>
        </div>
        <div class="col-12">
          <label for="photo" class="form-label">Photo (optional)</label>
          <vue-dropzone
              id="blogPhotoDropzone"
              ref="dropzone"
              :options="dropOptions"
              @vdropzone-file-added="photosUploading = true"
              @vdropzone-success="onDropzoneSuccess"
              @vdropzone-error="onDropzoneError"
              @vdropzone-queue-complete="photosUploading = false"
          />
        </div>
      </div>
      <button
          :class="['btn-lg', 'btn-primary', 'float-right', 'publish-btn', {'btn-disabled': isLoading}]"
          @click="publishPost"
      >
        Publish
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {IMG_API_URL, submitStatus} from "@/helpers/utils";
import Alert from "@/components/Alert.vue";
import vueDropzone from "vue2-dropzone";
import MarkdownRenderer from "@/components/MarkdownRenderer.vue";

export default {
  name: "AddBlogPost",
  components: {MarkdownRenderer, vueDropzone, Alert},
  data() {
    return {
      title: null,
      author: null,
      text: null,
      photo: null,
      resultMessage: null,
      dropOptions: {
        url: IMG_API_URL,
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        acceptedFiles: 'image/*',
        resizeWidth: 2560,
        maxFiles: 1,
      },
      photosUploading: false,
      photoUploadFailed: false,
      markdownExamples: [
        {type: 'Heading', syntax: '# Title goes here'},
        {type: 'Sub-heading (can add up to 6 #s)', syntax: '### Smaller heading!'},
        {type: 'Italics', syntax: '*italic text*'},
        {type: 'Bold text', syntax: '**bold text**'},
        {type: 'Link', syntax: '[Click here](https://picsum.photos/500/500)'},
        {type: 'Bullet point', syntax: '- list item'},
      ]
    }
  },
  watch: {
    resultMessage(to) {
      if (to) {
        // remove after a few seconds
        setTimeout(() => {
          this.resultMessage = null;
          this.photoUploadFailed = false;
        }, 4000);
      }
    },
    blogStatus(to) {
      if (to && to !== submitStatus.LOADING) {
        this.resultMessage = to === submitStatus.SUCCESS
            ? 'Blog post published successfully!'
            : 'Failed to publish blog post. Please ensure that title and text are both filled out.';
      }
    }
  },
  computed: {
    ...mapGetters(['blogStatus', 'getLoggedInUser']),
    userIsAdmin() {
      const user = this.getLoggedInUser;
      return user && user.permissions && user.permissions.is_admin;
    },
    isLoading() {
      return this.photosUploading || this.blogStatus === submitStatus.LOADING;
    },
    alertType() {
      return this.photoUploadFailed || this.blogStatus === submitStatus.FAILED ? 'danger' : 'success';
    },
  },
  methods: {
    ...mapActions(['publishBlogPost']),
    publishPost() {
      const post = {
        title: this.title,
        author: this.author,
        publishDate: new Date(),
        text: this.text,
        imageUrl: this.photo,
      }
      this.publishBlogPost(post);
      this.clearForm();
    },
    clearForm() {
      this.$refs.dropzone.removeAllFiles();
      this.title = null;
      this.author = null;
      this.text = null;
      this.photo = null;
    },
    onDropzoneSuccess(file, response) {
      this.photo = response.key;
      console.log('url is '+this.photo);
    },
    onDropzoneError(file) {
      this.photoUploadFailed = true;
      this.resultMessage = 'There was an error uploading the photo.';
    }
  }
}
</script>

<style scoped>
.add-blog-container {
  padding: 2rem;
}

.form-control {
  margin-bottom: 1rem;
}

.content-input {
  min-height: 35vh;
}

.publish-btn {
  margin: 1rem;
}
.markdown-container {
  padding: 1rem;
  margin-bottom: 1rem;
  max-height: 30vh;
  overflow: hidden;
  overflow-y: scroll;
}
</style>