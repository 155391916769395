<script>
import MarkdownIt from 'markdown-it'
export { Options } from 'markdown-it'
export default {
  name: "MarkdownRenderer",
  props: {
    source: { type: String, required: true},
    options: { type: Object, required: false}
  },
  data() {
    return {
      md: new MarkdownIt(this.options ?? {}),
    }
  },
  mounted(){
    this.$el.querySelector('#content-container').innerHTML = this.content;
  },
  watch: {
    content(to) {
      this.$el.querySelector('#content-container').innerHTML = to;
    }
  },
  computed: {
    content() {
      const src = this.source;
      return this.md?.render(src);
    },
  },
}
</script>

<template>
  <div>
    <div id="content-container"></div>
  </div>
</template>

<style scoped>

</style>