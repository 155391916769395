<template>
<div class="blog-container" id="top">
  <button v-if="scrollPosition > 400" class="btn btn-secondary btn-floating">
    <a class="back-to-top-text" href="#top">Back to Top</a>
  </button>
  <JumbotronNav :title="blogTitle" class="nav-section"/>
  <div class="blog-content">
    <div v-for="post in blogPosts" class="row page-bg light-teal-bg blog-post">
      <div class="row">
        <div :class="getTextWidth(post)">
          <h1>{{post.title}}</h1>
          <div class="metadata">
            <h5>{{formatDate(post.publishDate)}}</h5>
            <p v-if="post.author">By {{post.author}}</p>
            <hr>
          </div>
          <MarkdownRenderer :source="post.text"/>
        </div>
        <div class="col-lg-5 col-sm-12" v-if="post.imageUrl">
          <img class="blog-photo float-right" :src="getS3Url(post.imageUrl)"/>
        </div>
        <div v-if="userIsAdmin" class="col-12 delete-post">
          <button class="btn btn-lg btn-danger float-right" @click="deletePost(post._id)">
            Delete Blog Post
            <font-awesome-icon icon="trash"/>
          </button>
        </div>
      </div>
    </div>
    <div v-if="resultsLoading" class="bottom-spinner text-center">
      <div class="spinner-border text-info"><span class="sr-only">Loading...</span></div>
    </div>
    <div v-else-if="!blogPosts.length" class="text-center p-4">
      <h2>No blog posts yet.</h2>
      <p>Please check back later for the latest articles from the Ancestral Homes Network team!</p>
    </div>
  </div>
  <div v-if="showLoadMoreButton" class="row">
    <div class="col-12 text-center">
      <button class="btn btn-lg btn-primary" @click="getBlogPosts({'nextPage': 1})">Load More</button>
    </div>
  </div>
</div>
</template>

<script>
import JumbotronNav from "@/components/JumbotronNav.vue";
import MarkdownRenderer from "@/components/MarkdownRenderer.vue";
import {mapActions, mapGetters} from "vuex";
import {getS3Url, submitStatus} from "@/helpers/utils";

export default {
  name: "Blog",
  components: {MarkdownRenderer, JumbotronNav},
  data() {
    return {
      scrollPosition: 0,
      windowWidth: window.innerWidth,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted(){
    this.$gtag.pageview('Blog');
    this.getBlogPosts({'nextPage': 0});
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters(['blogPosts', 'canLoadMore', 'blogStatus', 'getLoggedInUser']),
    resultsLoading() {
      return this.blogStatus === submitStatus.LOADING;
    },
    showLoadMoreButton(){
      return this.canLoadMore && !this.resultsLoading && this.blogPosts.length > 0;
    },
    userIsAdmin() {
      const user = this.getLoggedInUser;
      return user && user.permissions && user.permissions.is_admin;
    },
    screenIsSmall() {
      return this.windowWidth <= 600;
    },
    blogTitle(){
      return this.screenIsSmall ? 'Ancestral Homes Blog' : 'AncestralHomes Blog';
    }
  },
  methods: {
    getS3Url,
    ...mapActions(['getBlogPosts', 'deleteBlogPost']),
    handleScroll() {
      // Check whether we're at the top of the page,in which case don't show 'scroll to top' button
      this.scrollPosition = window.scrollY;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    deletePost(postId){
      this.deleteBlogPost(postId);
      location.reload();
    },
    getTextWidth(post){
      return post.imageUrl && !this.screenIsSmall? 'col-7' : 'col-12';
    }
  }
}
</script>

<style scoped>
.blog-post {
  padding: 2rem;
  margin-bottom: 2.5rem;
}
.blog-photo {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}
.blog-container {
  padding: 1rem;
}
.blog-text {
  white-space: pre-wrap;
}
.bottom-spinner {
  margin: 2rem;
}
.delete-post {
  padding-top: 2rem;
}
.metadata {
  margin-bottom: 2rem;
}

@media screen and (max-width: 600px) {
  .blog-post {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .blog-container {
    padding: 0.25rem;
  }
}
</style>