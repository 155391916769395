<template>
  <div :class="['notification-container', {'fit-content': fitContent}]">
    <div
        v-if="showBadge"
        :class="['notification', 'ct-animate-blink', {'smaller-notification': small}]"
        v-tooltip="tooltipText"
    ></div>
    <slot name="notification-parent"/>
  </div>
</template>
<script>
export default {
  name: "NotificationBadge",
  props: {
    tooltipText: {type: String, default: null},
    showBadge: {type: Boolean, required: true},
    fitContent: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
  }
}
</script>

<style scoped>
.notification-container {
  position: relative;
}

.fit-content {
  width: fit-content;
}

.notification {
  position: absolute;
  top: -10px;
  right: -20px;
  left: 96%;
  bottom: 87%;
  height: 25px;
  width: 25px;
  background-color: #d9534f;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
}

.smaller-notification {
  height: 20px;
  width: 20px;
}

.ct-animate-blink {
  animation: grow 2s infinite;
  animation-fill-mode: both;
}

@keyframes grow {
  0% {
    transform: scale(0.75)
  }
  50% {
    transform: scale(1.25)
  }
  100% {
    transform: scale(0.75)
  }
}
</style>